import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import HeaderContainer from "../common/HeaderContainer";
import HeaderNew from "../common/HeaderNew";
import { Button } from 'primereact/button';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { RENDER_URL, START_LOADING, STOP_LOADING, BlockChainType, typeOfBlockchain, shortenWallet } from '../common/Constants';
import { GlobalDispatchContext } from "../context/Context"
import { addWallet, getAddedWallets, addVerifiedWallet, removeWallets,getUserAttributes,verifyEthWalletBio } from './walletService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import SolanaConnectButton from "../solana_wallet/CustomConnectButton";
// const chainArr = [{key:BlockChainType.ETH, value:"Ethereum"}, {key:BlockChainType.SOL, value:"Solana"}]
const chainArr = [BlockChainType.ETH, BlockChainType.SOL];

function CharacterContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayVerifyDialog, setDisplayVerifyDialog] = useState(false);
    const [selectedWalletItem, setSelectedWalletItem] = useState(null);
    const [solanaWalletConnected, setSolanaWalletConnected] = useState(null);
    const [solanaWalletConnectedAlert, setSolanaWalletConnectedAlert] = useState(false);
    const [position, setPosition] = useState('center');
    const toast = useRef(null);
    const _location = useLocation();
    const [showPopUpImmediatly, setShowPopUp] = useState(_location.state?.showPop ? _location.state.showPop : false);
    const [walletAddress, setWalletAddress] = useState('');
    const [userWalletList, setUserWalletList] = useState([]);
    const [walletErrorMsg, setWalletError] = useState("");
    const [chainType, setChainType] = useState(BlockChainType.ETH);
    // const [walletErrorMsg, setWalletError] = useState("");
    const [isVerified, setIsVerified] = useState(false);

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
        'displayVerifyDialog': setDisplayVerifyDialog
    }


    useEffect(() => {
        getAllAddedWallets();
        loadConnectedToken();
    }, [])

    useEffect(() => {

        if (showPopUpImmediatly) {
            onClick('displayBasic')
        }

    }, [showPopUpImmediatly])

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const onSolanaWalletConnection = (connected, address) => {
        setChainType(BlockChainType.SOL);
        console.log(`Solana Wallet connection status : `, connected);
        console.log(`Solana Wallet address : `, address);
        setSolanaWalletConnected(connected);
        setWalletAddress(address);
        console.log(`Addresses : `, userWalletList);
        let addr = userWalletList.filter((wallet) => {
            if (wallet.chain === BlockChainType.SOL) {
                return wallet.address;
            }
            return null;
        })
        console.log(`Filtered Addresses : `, addr);
        if (!addr.includes(address)) {
            console.log(`Current list does not include address`);
            addSolanaWallet(address);
        }
        else {
            /// Wallet already added and verified
            console.log(`Current list includes address`);

        }
        // setSolanaWalletConnectedAlert(true);
        
    }

    const loadConnectedToken = () => {
        dispatch({
            type: START_LOADING,
        });

        //get attributes
        getUserAttributes().then((result) => {
            if (result.data.attributes) {
                setIsVerified(result.data.attributes.verified);
                dispatch({
                    type: STOP_LOADING,
                });
            }
        }).catch((error) => {
            console.log("error", error);
            showError(error);
            dispatch({
                type: STOP_LOADING,
            });
        })
    }

    const getAllAddedWallets = () => {

        dispatch({
            type: START_LOADING,
        });

        getAddedWallets().then((response) => {

            if (response.data.success) {

                setUserWalletList(response.data.data);
                dispatch({
                    type: STOP_LOADING,
                });
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });
            console.log(error);
        })

    }

    const showSuccess = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: `${message}`, life: 3000 });
    }

    const showError = (errormsg) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: `${errormsg}`, life: 3000 });
    }

    const onAddingWallet = () => {
        dispatch({
            type: START_LOADING,
        });
        let data = {
            wallet_token: walletAddress
        }
        onHide('displayBasic');
        addWallet(data).then((response) => {
            if (response.data.success) {
                //todo put success toast  green
                setWalletAddress("");
                getAllAddedWallets();
                dispatch({
                    type: STOP_LOADING,
                });
                showSuccess(response.data.message)
            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                //toado show toast red
                console.log(response.data.error);
                showError(response.data.error);
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });
            //todo show toast red
            showError(error.msg);
            console.log(error);
        })


    }
    const addSolanaWallet = (solanaWalletAddress) => {
        dispatch({
            type: START_LOADING,
        });
        let data = {
            wallet_token: solanaWalletAddress
        }
        // onHide('displayBasic');
        addVerifiedWallet(data).then((response) => {
            if (response.data.success) {
                //todo put success toast  green
                setChainType(BlockChainType.UNKNOWN);
                setWalletAddress("");
                getAllAddedWallets();
                dispatch({
                    type: STOP_LOADING,
                });
                showSuccess(response.data.message)
            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                //toado show toast red
                console.log(response.data.error);
                showError(response.data.error);
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });
            //todo show toast red
            showError(error.msg);
            console.log(error);
        })


    }

    const onRemoveWallet = (walletAddress) => {

        dispatch({
            type: START_LOADING,
        });

        let data = {
            wallet_token: walletAddress.address
        }

        removeWallets(data).then((response) => {
            if (response.data.success) {
                //todo toast msg 
                getAllAddedWallets();
                showSuccess(response.data.message);
            }
            else {
                showError(response.data.error);
            }

            dispatch({
                type: STOP_LOADING,
            });

        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: STOP_LOADING,
            });

            showError(error.msg);

        })

    }
    const onVerifyWallet = (rowData) => {
        if (rowData.chain === 'eth') {
            setSelectedWalletItem(rowData);
            EthVerificationDialog(rowData);
        }
        else if (rowData.chain === 'sol'){
            /// Use solana wallet adapter to verify
        }
        else {
            showError("Verification not supported for this wallet.");
        }
    }
    const verifyEthereumWallet = (rowData) => {
        verifyEthWalletBio(rowData.address).then((response) => {
            if (response.data.success) {
                //todo put success toast  green
                setWalletAddress("");
                getAllAddedWallets();
                dispatch({
                    type: STOP_LOADING,
                });
                showSuccess(response.data.message)
            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                //toado show toast red
                console.log(response.data.error);
                showError(response.data.error);
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });
            //todo show toast red
            showError(error.msg);
            console.log(error);
        })
    }

    const removeWalletButton = (rowData) => {
        return <Button label='Remove' onClick={() => { onRemoveWallet(rowData) }}></Button>
    }
    const EthVerificationDialog = (rowData) => {
        onClick('displayVerifyDialog')
    }

    const walletAddressDataTableRow = (rowData) => {
        return <label > {shortenWallet(rowData.address, 32, true)} </label>
    }
    const verifyWalletButton = (rowData) => {
        if (rowData.verified) {
            return <label  style={{color:'green'}} > Verified</label>
        }
        return <Button label='Verify' onClick={() => { onVerifyWallet(rowData) }}></Button>
    }

    const renderVerificationCode = (rowData) => {
        return (
            <div>
                <label maxlength="10" style={{ color:'green'}} title="Click to copy" tooltip="Click to copy" tooltipOptions={{ position: 'top' }} onClick={() => { navigator.clipboard.writeText(rowData.code); showSuccess("Verification Code copied to clipboard")}}> {rowData.code}</label>
                {/* <Button  icon="pi pi-copy" onClick={() => onAddingWallet()} /> */}
            </div>
        );
    }
    const renderFooter = (name) => {
        // setWalletError('');
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                {chainType === BlockChainType.ETH && <Button label="Add wallet" onClick={() => {
                    if (typeOfBlockchain(walletAddress) === BlockChainType.ETH) {
                        setWalletError('');
                        onAddingWallet()
                    }
                    else {
                        setWalletError("Invalid wallet address")
                    }
                }} autoFocus className='p-button-success' />}
                {/* {chainType === BlockChainType.SOL && <SolanaConnectButton/>} */}
            </div>
        );
    }
    const renderFooterForVerificationDialog = (rowData) => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setDisplayVerifyDialog(false)} className="p-button-text" />
                <Button label="Verify" icon="pi pi-check" onClick={() => {setDisplayVerifyDialog(false); verifyEthereumWallet(rowData)}} autoFocus />
            </div>
        );
    }
    const conditionalRowStyles = [
        {
          when: row => true,
          style: row => ({
            backgroundColor: row.verified ? 'green' : 'red',
          }),
        },
      ];

      const chainDropdownItemTemplate =
        (option) => {
            return (
                option?.key == "unknown" ?
                    <div className='col-lg-12 col-md-12 col-sm-12 text-left'><b style={{ color: 'green' }}>{option?.value}</b></div>
                    : <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-left'>{option?.value}</div>
                    </div>


            );
        };
      

    return (
        <>
            <div className="character_section">
                <HeaderNew active={RENDER_URL.WALLET}></HeaderNew>
                <div className="container-fluid container_home">
                    <div className='container'>

                        <Toast ref={toast} position="top-right" />

                        {/* <div className="pagetitle" style={{ padding: '20px' }}>
                            <h1 style={{color:'white'}}>Wallet</h1>
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/collectables" style={{color:'white'}}>Home</Link></li>
                                    <li className="breadcrumb-item active" style={{color:'white'}}>Wallet</li>
                                </ol>
                            </nav>
                        </div> */}

                        <Dialog header="Header" visible={solanaWalletConnectedAlert} style={{ width: '50vw' }} onHide={() => setSolanaWalletConnectedAlert(false)}>
                            <p className="m-0">
                                We have successfully verified your solana wallet. You may now disconnect it. 
                            </p>
                        </Dialog>

                        <section className="section dashboard mt-6">
                            <div className="container">
                                <div className="row">
                                    {/* <div className="col-xxl-12 col-md-12"> */}
                                    <div className="col-lg-12">
                                        {/* <div className="card customers-card sales-card"> */}
                                        {/* <div className="filter">
                                                <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots" /></a>
                                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                    <li className="dropdown-header text-start">
                                                        <h6>Filter</h6>
                                                    </li>
                                                    <li><a className="dropdown-item" href="#">Today</a></li>
                                                    <li><a className="dropdown-item" href="#">This Month</a></li>
                                                    <li><a className="dropdown-item" href="#">This Year</a></li>
                                                </ul>
                                            </div> */}
                                        {/* <div className="col-lg-12"> */}
                                        <div className="row">
                                            <div className="col-xxl-3 col-md-6">
                                                <div className="card customers-card sales-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Add Ethereum Wallet</h5>
                                                        <div className="d-flex align-items-center">
                                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                                <i className="pi pi-wallet" />
                                                            </div>
                                                            <div className="ps-3">
                                                                <Button className='p-button-success' disabled={!isVerified} label="Add Wallet" onClick={() => {setChainType(BlockChainType.ETH); onClick('displayBasic')}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-3 col-md-6">
                                                <div className="card customers-card sales-card">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Add Solana Wallet</h5>
                                                        <div className="d-flex align-items-center">
                                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                                <i className="pi pi-wallet" />
                                                            </div>
                                                            <div className="ps-3">
                                                                <SolanaConnectButton state={solanaWalletConnected} setData={onSolanaWalletConnection}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                        {/* </div> */}

                                        <Dialog header="Add Wallet" visible={displayBasic} style={{ width: '50vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                                            <div className='row text-center mt-5'>
                                                <div className='col-sm-12'>
                                                    {/* <Dropdown value={chainType} onChange={(e) => setChainType(e.value)} options={chainArr} 
                                                        placeholder="Select Chain"  className="form-control" /> */}
                                                    {/* <span className='p-error' style={{ padding: '10px' }}>{walletErrorMsg}</span> */}
                                                    {chainType === BlockChainType.ETH &&
                                                        <div className='col-sm-12'>
                                                            {/* <br></br> */}
                                                            <input visible={chainType === BlockChainType.ETH} type="text" className='form-control' placeholder='Enter Ethereum wallet address' value={walletAddress} onChange={(e) => { setWalletAddress(e.target.value) }} />
                                                        </div>
                                                    }
                                                    <span className='p-error' style={{ padding: '10px' }}>{walletErrorMsg}</span>
                                                </div>
                                            </div>
                                        </Dialog>

                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className="card recent-sales overflow-auto">
                                            <DataTable value={userWalletList} emptyMessage="No wallet found" responsiveLayout="scroll" scrollable scrollHeight="flex" conditionalRowStyles={conditionalRowStyles}>
                                                <Column field="address" header="Wallet Address" body={walletAddressDataTableRow}></Column>
                                                <Column field="chain" header="Chain"></Column>
                                                <Column header="Code" body={renderVerificationCode}></Column>
                                                <Column header="Remove" body={removeWalletButton}></Column>
                                                <Column header="Verify" body={verifyWalletButton}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>

                                <Dialog header="Verify Wallet" visible={displayVerifyDialog} style={{ width: '50vw' }} footer={renderFooterForVerificationDialog(selectedWalletItem)} onHide={() => onHide('displayVerifyDialog')}>
                                    <div className='row text-center mt-5'>
                                        <div className='col-sm-12'>
                                            <p className="mb-2">
                                                You will need to verify your NFT ownership on the Ethereum chain by pasting a verification code inside your OpenSea profile bio. Our system will read this code to verify ownership of the NFTs so you can use them in Degen Royale. Once you have added the code click on the verify button below.
                                            </p>
                                            <p className="mb-5">
                                                <strong>To verify:</strong> please visit opensea.io, connect your wallet containing the NFTs, go to your profile settings, and paste the following code in your Bio field:
                                            </p>
                                            <label>
                                                {/* <br></br> */}
                                                <p maxlength="10" style={{ color: 'black' }} title="Click to copy" tooltip="Click to copy" tooltipOptions={{ position: 'top' }} onClick={() => { if (selectedWalletItem) { navigator.clipboard.writeText(selectedWalletItem.code); showSuccess("Verification Code copied to clipboard") } }}> Code : {selectedWalletItem?.code}</p>
                                                {/* <Button  icon="pi-copy" onClick={() => onAddingWallet()} /> */}
                                                {/* <br></br> */}
                                            </label>
                                            <p className="mb-2">
                                                Please verify your NFT ownership connecting the wallet you have added to your Degen Royale account. Click on the verify button below to use the Solana Labs wallet adapter.
                                            </p>
                                            <p className="mb-2">
                                                Without wallet verification you will not be able to use your in-game NFT avatars, items and weapons by <time>December 18, 2023</time>.
                                            </p>
                                            <span className='p-error' style={{ padding: '10px' }}></span>
                                        </div>
                                    </div>
                                </Dialog>

                            </div>
                        </section>
                        <br></br>
                        <br></br>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CharacterContainer